.card {
    background-color: var(--card-bg-color);
    color: var(--card-text-color);
    border-color: var(--card-border-color);
    position: relative;
    border: var(--card-border);

    .card-overlay {
        background-color: var(--card-overlay-bg-color);

        &:hover {
            background-color: var(--card-overlay-hover-bg-color);
        }
    }
}

$image-height: 230px;
$image-width: 160px;

.card-item-container {
    .card {
        max-width: $image-width;
        cursor: pointer;
        padding-left: 0px;
        padding-right: 0px;
        box-sizing: border-box;
        position: relative;
        color: var(--card-text-color);
        border: 1px var(--card-border-color);

    }

    .card-title {
        font-size: 13px;
        width: 140px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: block;
        margin-top: 2px;
        margin-bottom: 0px;
        text-align: center;
    }

    .overlay-information {
        position: absolute;
        top: 5px;
        left: 5px;
        border-radius: 15px;
        padding: 0 10px;
        background-color: var(--card-bg-color);

        &.overlay-information--centered {
            top: 95px;
            left: 36px;
        }
    }

    .overlay {
        height: $image-height;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        z-index: 10;

        &:hover {
            visibility: visible;
        }
    }


    .card-body {
        padding: 5px !important;
        background-color: var(--card-bg-color);
        border-width: var(--card-border-width);
        border-style: var(--card-border-style);
        border-color: var(--card-border-color);
        border-radius: 0.25em;
    }


    .card-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: $image-height;
        z-index: 10;
        transition: all 0.2s;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
}
